import React from 'react';
import { graphql } from 'gatsby';
import WebsiteDefaultPage from '../components/Page/Base';
import BlogHero from '../components/PageSpecific/community/blogs/BlogHero';
import { JoinSection } from '../components/SharedSections';
import { Color } from '../styles';
import { LIGHT_DARK } from '../constants';
import { createGlobalStyle } from 'styled-components';

const GlobalStyleOverride = createGlobalStyle`
   #body {
      min-height: unset;
    }
`;

export const BlogQuery = graphql`
query SingleBlogQuery($id: String!) {
    sanityBlogs(id: { eq: $id }) {
      title
      _rawBody
      date
      mainImage {
        asset {
          gatsbyImageData(fit: FILL, placeholder: BLURRED)
          url
        }
      }
    }
  }
`;

const BlogsTemplate = ({data}) => {
  return (
  <>
    <GlobalStyleOverride />
    <WebsiteDefaultPage bodyBackground={Color.OFF_WHITE} pageState={{ theme: LIGHT_DARK.LIGHT }}>
      <BlogHero data = {data}/>
      <JoinSection />
    </WebsiteDefaultPage>
  </>
  ); 
}

export default BlogsTemplate;
