import React from 'react';
import { GatsbyImage} from 'gatsby-plugin-image';
// @ts-ignore
import { EventDesc, Header, Blurb } from '../BaseHeroStyledComponents';
import { navigate } from 'gatsby';
import { ArrowLeft} from '../../../Svg/arrows';
import styled from 'styled-components';
import MyPortableText from '../../../MyPortableText';
import { marginTop, columnStartEnd, flex, maxWidth, height, widths, paddingTopBottom, fullColorBackground, gridColumns, columnSpacing, Color } from '../../../../styles';

const BackDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BlogImageContainer = styled.div`
  ${marginTop(20, 26, 30)};
  margin-inline: auto;
  ${columnStartEnd([1, 9], [8, 13], [9, 13])};
  ${flex('center', 'center')};
  ${maxWidth('100rem', '100rem', '40rem')};
`;

const BlogWrapper = styled.section`
  ${paddingTopBottom([136, 40], [152, 80], [206, 120])};
  ${fullColorBackground(Color.OFF_WHITE)};
  display: grid;
  ${gridColumns(8, 12)};
  ${columnSpacing(8, 16, 24)};
  ${widths('35rem', '40rem', '70rem')};
  ${maxWidth('50rem', '68.8rem', '100rem')};
  margin-inline: auto;
`;

const BlogHero = ({data}) => {
  const blog = data.sanityBlogs;

  return (
  <BlogWrapper>
     <button onClick={() => navigate(-1)}><BackDiv>{ArrowLeft}<h3><u>Back</u></h3></BackDiv></button>
    <Blurb>
      <p>{blog.date}</p>
      <BlogImageContainer>{!blog.mainImage ? null : <GatsbyImage className={'main-image'} image={blog.mainImage.asset.gatsbyImageData} alt ="Blog Image"/>}</BlogImageContainer>
      <Header>{blog.title}</Header>
      <EventDesc style={{paddingLeft: '2em'}}>
        <MyPortableText value={blog._rawBody} />
      </EventDesc>
    </Blurb>
  
  </BlogWrapper>
  )
};

export default BlogHero;